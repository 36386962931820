import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  constructor() { }


  setTour(key: any, data: any){
    localStorage.setItem(key, data);
  }

  getTour(key: any) {
    return localStorage.getItem(key);
  }

  setLogin(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getLogin(key: any) {
    return localStorage.getItem(key);
  }

  // setFavorite(key: any, data: any){
  //   localStorage.setItem(key, data );
  // }
  // getFavorite(key: any) {
  //   return localStorage.getItem(key);
  // }


  setBusinessId(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getBusinessId(key: any) {
    return localStorage.getItem(key);
  }

  setAnniversary(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getAnniversary(key: any) {
    return localStorage.getItem(key);
  }


  setBirthday(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getBirthday(key: any) {
    return localStorage.getItem(key);
  }

  setCustomerType(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getCustomerType(key: any) {
    return localStorage.getItem(key);
  }

  setDateJoined(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getDateJoined(key: any) {
    return localStorage.getItem(key);
  }


  setEmail(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getEmail(key: any) {
    return localStorage.getItem(key);
  }


  setFirstName(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getFirstName(key: any) {
    return localStorage.getItem(key);
  }


  setLastName(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getLastName(key: any) {
    return localStorage.getItem(key);
  }

  setId(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getId(key: any) {
    return localStorage.getItem(key);
  }

  setMobileVerificationAmount(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getMobileVerificationAmount(key: any) {
    return localStorage.getItem(key);
  }

  setPhone(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getPhone(key: any) {
    return localStorage.getItem(key);
  }

  setPhoneVerified(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getPhoneVerified(key: any) {
    return localStorage.getItem(key);
  }


  setProfileComplete(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getProfileComplete(key: any) {
    return localStorage.getItem(key);
  }


  setQrText(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getQrText(key: any) {
    return localStorage.getItem(key);
  }

  setUdId(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getUdId(key: any) {
    return localStorage.getItem(key);
  }

  setUrl(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getUrl(key: any) {
    return localStorage.getItem(key);
  }


  setCustStripe(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getCustStripe(key: any) {
    return localStorage.getItem(key);
  }

  setEmailVerificationCode(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getEmailVerificationCode(key: any) {
    return localStorage.getItem(key);
  }

  setEmailVerified(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getEmailVerified(key: any) {
    return localStorage.getItem(key);
  }

  setFirstDiscount(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getFirstDiscount(key: any) {
    return localStorage.getItem(key);
  }

  setImage(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getImage(key: any) {
    return localStorage.getItem(key);
  }


  setNickname(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getNickname(key: any) {
    return localStorage.getItem(key);
  }

  setOrdersCount(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getOrdersCount(key: any) {
    return localStorage.getItem(key);
  }

  setPhoneVerificationCode(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getPhoneVerificationCode(key: any) {
    return localStorage.getItem(key);
  }

  setPlayerId(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getPlayerId(key: any) {
    return localStorage.getItem(key);
  }


  setStatus(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getStatus(key: any) {
    return localStorage.getItem(key);
  }


  setStatusAnniversary(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getStatusAnniversary(key: any) {
    return localStorage.getItem(key);
  }

  setStatusBirthday(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getStatusBirthday(key: any) {
    return localStorage.getItem(key);
  }

  setSubscribed(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getSubscribed(key: any) {
    return localStorage.getItem(key);
  }

  setUnSubscribed(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getUnSubscribed(key: any) {
    return localStorage.getItem(key);
  }

  setDiscountText(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getDiscountText(key: any) {
    return localStorage.getItem(key);
  }

  setDiscountValue(key: any, data: any){
    localStorage.setItem(key, data );
  }

  getDiscountValue(key: any) {
    return localStorage.getItem(key);
  }
  setUserAddress(key:any,data:any){
    localStorage.setItem(key,data)
  }
  getUserAddress(key: any) {
    return localStorage.getItem(key);
  }
  setShareUserLocation(key:any,data:any){
    localStorage.setItem(key,data)
  }
  getShareUserLocation(key: any) {
    return localStorage.getItem(key);
  }
  
  setItem(key: any, data: any){
    localStorage.setItem(key, data);
  }

  getItem(key: any) {
    return localStorage.getItem(key);
  }

  
  setStringifyItem(key: any, data: any) {
    this.setItem(key, JSON.stringify(data));
  }

  
  getStringifyItem(key: any) {
   return JSON.parse(this.getItem(key));
  }

  hasKey(key: any) {
    if (this.getItem(key) === null) {
      return false;
    }

    return true;
  }

  removeItem(key: any) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

}
