import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { ComponentsModule } from './components/components.module';
import { FormsModule,ReactiveFormsModule,FormBuilder } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonBottomDrawerModule } from 'ion-bottom-drawer';
// import { ImageViewerModule } from 'ng2-image-viewer';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { GlobalVariable } from './global';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
// import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { ImageViewerModule } from "ngx-image-viewer";
import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { QRCodeModule } from 'angularx-qrcode';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { CalendarModule } from "ion2-calendar";
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';
// import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
// import { QRCodeModule } from 'angularx-qrcode';
// declare module "@angular/core" {
//   interface ModuleWithProviders<T = any> {
//       ngModule: Type<T>;
//       providers?: Provider[];
//   }
// }
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
       NgCircleProgressModule.forRoot({
    // set defaults here
    radius: 100,
    outerStrokeWidth: 16,
    innerStrokeWidth: 8,
    outerStrokeColor: "#78C000",
    innerStrokeColor: "#C7E596",
    animationDuration: 300,
  }),
    IonicModule.forRoot(),
    IonBottomDrawerModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ImageViewerModule.forRoot(),
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true
    }),
    AppRoutingModule,
    ComponentsModule,
    QRCodeModule,
    // AppVersion,
    // ImageViewerModule,
    // NgxIonicImageViewerModule,
   
   
  ],
  providers: [
    GlobalVariable,
    PDFGenerator,
    Geolocation,
    NativeGeocoder,
    PhotoViewer,
    StatusBar,
    LaunchNavigator,
    NativeStorage,
    Stripe,
    AndroidPermissions,
    // ImageViewerModule,
    File,
    Camera,
    LocationAccuracy,
    InAppBrowser,
    
    FormsModule,ReactiveFormsModule,FormBuilder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
