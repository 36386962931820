import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DrawerState } from 'ion-bottom-drawer';
import { GlobalVariable } from './global';
import { LocalStorageService } from './services/core/local-storage.service';
import { UserApiService } from './services/user-api.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CONFIG } from './services/app-config.service';
import { RouterOutlet, ActivationStart } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;  
  dockedHeight: 50
  shouldBounce: true
  disableDrag: false
  distanceTop: 0
  transition: "0.25s"
  state: DrawerState.Docked
  minimumHeight: 60
  places: any;
  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private navCTRL: NavController,
    private localStorageService: LocalStorageService,
    public globals:GlobalVariable,
    public server:UserApiService,
    public statusbar:StatusBar,
    

  ) {
    this.initializeApp();
  }

  initializeApp() {
   console.log('hostname    ',window.location.hostname);
   this.globals.hostName=window.location.hostname;
   if(this.globals.hostName!='localhost'){
    this.globals.inWeb=true;

    this.getHostData();

   }
  else{
    // this.getWelcomeBanners();
    this.getbusiness()
  }
   
    var x = navigator.onLine;
    console.log('Online is ',x)
    if(x!=true){
      this.globals.presentToast('Offline');

    }
    if(!this.globals.inWeb){
      this.navCTRL.navigateRoot('splash')
    }
    // this.router.navigate(['splash'])
    this.platform.ready().then(() => {
      this.statusbar.overlaysWebView(false);
      if (window.statusbar) {
        this.statusbar.show();
      }
      
      // this.router.navig
     
      //..................h
      this.storage.get('darktheme').then((res) => {
        console.log('darktheme', res);
        if (res == true) {
          document.body.classList.toggle('dark', res)
          this.statusbar.backgroundColorByHexString("#000000");
          this.statusbar.styleLightContent();
        }
        else {
          document.body.classList.toggle('light', res)
          this.statusbar.backgroundColorByHexString("#ffffff");
          this.statusbar.styleDefault();
        }

      });
      //.................
    
      //12:35/ 12-5-2021
  
      // loading busisnessdata
      // this.getbusiness();
      //checking dark theme
     


      // ALi Hassan
     

      // if (this.localStorageService.getTour('Tour') == 'true' && this.localStorageService.getLogin('isLogin') == 'false') {
      //   // this.router.navigate(['login'])
      //   this.router.navigate(['tabs/tabs/home']);
      // }

      // if (this.localStorageService.getTour('Tour') == 'true' && this.localStorageService.getLogin('isLogin') == 'true') {
      //   this.router.navigate(['tabs/tabs/home'])
      // }
      
      // ALi Hassan


      // this.storage.get('loginData').then(res=>{
      //   console.log(res)
      // })
      // this.storage.get('sliderInfo').then(result=>{
      //   console.log('slider is Shown 1 time =>'+result)
      //   if(result==true){
      //     // this.router.navigateBy[('/tabs/tabs/home')]

      // this.platform.ready().then(() => {
      // this.storage.get('loginData').then(res=>{
      //   console.log(res)
      // })
      // this.storage.get('sliderInfo').then(result=>{
      //   console.log('slider is Shown 1 time =>'+result)
      //   if(result==true){
      //     // this.router.navigateBy[('/tabs/tabs/home')]

      //     this.router.navigateByUrl('/tabs/tabs/home');
      //   }
      //   else{
      //     // this.router.navigate[('/slider')]
      //     this.router.navigate[('/slider')]
      //   }
      // })

    });
  }
  //for web
  getHostData(){
    
    this.server.getHostInfo().subscribe(res=>{
      console.log('data from host api   ',res);
     CONFIG.business_id= res.business_id;
     CONFIG.business_username=res.username;
     CONFIG.splash_image=res.logo;
     CONFIG.splash_text=res.name;
     this.getbusiness();
      // this. res.id
    })
  }
  getWelcomeBanners(){
    this.server.welcomeBanners().subscribe(res=>{
      console.log('welcome banner',res)
      this.globals.welcomeTitle=res.welcome_screen_title;
      this.globals.welcomeSubtitle=res.welcome_screen_subtitle;
      this.globals.banner_image= "url("+res.welcome_screen_image+') 100% 100%/100%';
    })
  }
  getbusiness(){
   this.globals.tempID= CONFIG.business_id
    let busID=localStorage.getItem('activeBranchid')
    if(busID!=null && busID!='null'){
      console.log('saved branch id');
      CONFIG.business_id=busID;
      this.globals.branch_enabled=0;

    }
    this.server.getBusiness().subscribe(res=>{
      console.log(res,'business rec')
      if(res.status=="true"){
        //setting business
       this.globals.businessData= res.results[0];
       this.globals.businessName=this.globals.businessData.name
       this.globals.businessId=this.globals.businessData.business_id;
       this.globals.qrEnabled=this.globals.businessData?.app_qr;
       if(this.globals.qrEnabled==undefined){
         this.globals.qrEnabled=true
       }
       console.log('app qr',this.globals.qrEnabled)
       this.globals.point_check = this.globals.businessData.points_enabled;
       this.globals.punch_check = this.globals.businessData.punches_enabled;
       this.globals.special_offer = this.globals.businessData.special_offer;
       this.globals.events_enabled = this.globals.businessData.events_enabled;
       this.globals.gallery_enabled = this.globals.businessData.gallery_enabled;
       this.globals.pickup = this.globals.businessData.pickup;
       this.globals.latitude = this.globals.businessData.latitude;
       this.globals.longitude = this.globals.businessData.longitude;
       this.globals.menu_ready_msg = this.globals.businessData.menu_ready_message;
       this.globals.hours_operation = this.globals.businessData.hours_operation;
       this.globals.branch_enabled = this.globals.businessData.branch_enabled;
       this.globals.giftcard_enabled = this.globals.businessData.giftcard_enabled;
       this.globals.b_logo = this.globals.businessData.logo;
       this.globals.home_logo = this.globals.businessData.logo;

       this.globals.business_name = this.globals.businessData.name;
       this.globals.business_phone = this.globals.businessData.phone;
       this.globals.business_address = this.globals.businessData.address;
       this.globals.StripId = this.globals.businessData.stripe_id;
       this.globals.order_instructions = this.globals.businessData.instructions_enabled;
       this.globals.pickup_timing = this.globals.businessData.pickup_timing;
       this.globals.delivery_timing = this.globals.businessData.delivery_timing;
       this.globals.business_username = this.globals.businessData.username;
       this.globals.estimated_time = this.globals.businessData.delivery_time;
       this.globals.business_discount_count = parseInt(this.globals.businessData.business_discount_count);
       this.globals.username = this.globals.businessData.username;
       this.globals.bussinessId = this.globals.businessData.business_id;
       this.globals.admin_stripe = this.globals.businessData.admin_stripe_enabled;
       this.globals.pickupsetting = this.globals.businessData.delivery_time;
       this.globals.tax = this.globals.businessData.tax;
       this.globals.deliveryCharges = this.globals.businessData.delivery_fee;
       this.globals.pickup_Time = this.globals.businessData.pickup_time;
       this.globals.minimun_order = parseInt(this.globals.businessData.minimum_order);
       this.globals.availed_discount_count = parseInt(this.globals.businessData.customer_discount_availed_count);
       this.globals.paypalId = this.globals.businessData.paypal_id;
       this.globals.Timing = this.globals.businessData.hours_operation;
       this.globals.specific_delivery_day = this.globals.businessData.specific_delivery_day;
       this.globals.review_enabled = this.globals.businessData.review_enabled;
       this.globals.delivery_day = this.globals.businessData.delivery_day;
       this.globals.authorize_enabled = this.globals.businessData.authorize_enabled;
       this.globals.card_enabled = this.globals.businessData.card_enabled;
       this.globals.admin_stripe_enabled = this.globals.businessData.admin_stripe_enabled;
       this.globals.catering_enabled = this.globals.businessData.catering_enabled;
       this.globals.catering_cart_enabled = this.globals.businessData.catering_cart_enabled;
       this.globals.giftcard_amount_limit = this.globals.businessData.giftcard_limit;
       this.globals.business_type = this.globals.businessData.business_type;
       this.globals.appointment_enabled = this.globals.businessData.appointment_enabled;
       this.globals.website=this.globals.businessData.website;
       this.globals.orders_enabled = this.globals.businessData.orders_enabled;
       this.globals.BusinessDiscount =parseInt(this.globals.businessData.discount);
       this.globals.ccFeeDisclaimer = this.globals.businessData.ccFeeDisclaimer;
       this.globals.tip_enabled = this.globals.businessData.tip_enabled;
       this.globals.menu_ready = this.globals.businessData.menu_ready;
       this.globals.delivery_time=this.globals.businessData.delivery_time;
       this.globals.doordashEnabled = this.globals.businessData.doordash_enabled;
       document.getElementById('title').innerHTML=this.globals.businessData.name
   
       if (this.globals.pickup == '1') {
        this.globals.pickup = true;
    } else {
        this.globals.pickup = false;
    }

    if (this.globals.businessData.delivery == '1') {
        this.globals.delivery = true;
    } else {
        this.globals.delivery = false;
    }

    if (this.globals.businessData.cash_enabled == '1') {
        this.globals.cash_enabled = true;
    } else {
        this.globals.cash_enabled = false;
    }

    if (this.globals.pickup == '1') {
        this.globals.pickup = true;
    } else {
        this.globals.pickup = false;
    }

    if (this.globals.businessData.delivery == '1') {
        this.globals.delivery = true;
    } else {
        this.globals.delivery = false;
    }


    
   
    if(this.globals.inWeb==true){
      // this.globals.inWeb=true;
      // console.log('web url is same.....',this.globals.webURL)
      this.getCategories();

    }
       this.loadBanner();
       if ( this.globals.businessData.appColor) {
         console.log('app coloris     ....',this.globals.businessData.appColor);
        this.globals.appColor =  this.globals.businessData.appColor;
          document.documentElement.style.setProperty('--ion-color-primary', this.globals.appColor);
    } else {
        // this.globals.appColor = '#000000'
    }


  // if(this.globals.branch_enabled==1){
  //   this.getbusiness();
  // }
    

      }
    })
  }
  loadBanner() {
    let response = this.server.LoadBanners()
    response.subscribe(data => {
      this.globals.isDataLoaded=true;

     

        console.log('banners', data)
        this.globals.banner_color = data.banner_color;
        this.globals.giftCard_color = data.gift_cards_color;
        this.globals.myRewards_color = data.my_rewards_color;
        this.globals.orderNow_color = data.order_now_color;
        this.globals.specialOffer_color = data.special_offer_color;
        this.globals.themeColor = data.theme_color;
        this.globals.cash_discount = data.cash_discount;
      this.globals.banners=data.data
      this.globals.ring_image=data.ring_image;
        this.globals.cash_discount_enabled = data.cash_discount_enabled;
        this.globals.cash_discount_percentage = data.cash_discount_percentage;
        this.globals.cash_discount_value = data.cash_discount_value;
        if (data.theme_color) {
          console.log('primary color ',data.theme_color)
            this.globals.appColor = data.theme_color;
           
            document.documentElement.style.setProperty('--ion-color-primary', this.globals.appColor);
        } else {
            // this.globals.appColor = '#000000'
        }

        this.globals.cash_discount = data.cash_discount;

        this.globals.cash_discount_enabled = data.cash_discount_enabled;
        this.globals.cash_discount_percentage = data.cash_discount_percentage;
        this.globals.cash_discount_value = data.cash_discount_value;

    }, error => {
        console.log('no network', error)
        // let alert = this.alertCtrl.create({
        //     message: 'Seems like your Network is not providing service. Try recharging',
        //     buttons: ['OK']
        // })
        // alert.present();
    });
}
getCategories() {
  console.log('getting menu')
  this.globals.loader()
    
  // this.globals.isDataLoaded = false;
  this.server.getCategories().subscribe(
    (res) => {
  this.globals.dismiss();

      // this.globals.isDataLoaded = true;
    //   this.loadingMenu=true;
    // setTimeout(() => {
    //   this.loadingMenu=false;
      
    // }, 3000);
      
      console.log('loaded...............');
      this.globals.scrollEvents= res.restaurant.app_menu;
      console.log(this.globals.scrollEvents,'scroll setting')
      // this.globals.businessName=res.restaurant.name;
      // this.globals.businessId=res.restaurant.id;
      this.globals.superCategories = res.super_categories;
      this.globals.superCategories.forEach((categories) => {
        categories.categories.forEach((category) => {
          console.log(category);
          category.isAvailabe= this.checkTiming(categories?.timings)
          category.items.forEach((item) => {
            console.log('checking .....timing 1st time')
            // item.background = this.getColor();
            item.isAvailabe = this.checkTiming(item.item_timings);
          });
          console.log(this.globals.superCategories);
        });
      });
      if(this.globals.superCategories.length>0){
        this.globals.Homecategories = this.globals.superCategories[0]?.categories

        this.globals.activeSuperCatID = this.globals.superCategories[0].id//active segment purpose only
      //  let router= this.router.url
      //  console.log(router,'router data')

      //  if(router=='/'){
      //   this.router.dispose();
      //   this.router.navigate(['tabs/tabs/menu']);
      //  }
      //  else{
        // this.router.dispose()
        // this.router.createUrlTree(['/tabs/tabs/menu'])
        // this.router.events.subscribe(e => {
        //   console.log('outlet',e)
          
        //   if (e instanceof ActivationStart){

        //     console.log('outlet in ',e.snapshot.outlet)
            
        //     this.outlet.deactivate();
            this.router.navigate(['tabs/tabs/menu'],{replaceUrl:true}).then(res=>{
              console.log(res,'navigation');
              // if(!res){
              //   this.router.navigate([])
              // }
            })
          // }
            // this.outlet.deactivate();
        // });
       
      //  }
       
        // this.router.navigate(['/'],{replaceUrl:true}).then(res=>{
        //   console.log(res,'navigation');
        // })

        this.time_change();
      }
   
      console.log('0 index super ..',this.globals.Homecategories)
     
    },
    (err) => {
  this.globals.dismiss();

      this.globals.isDataLoaded = true;
      this.globals.presentToast('Network Error, try again');
    }
  );
}
checkTiming(Timing) {
  console.log(Timing);
  if (Timing ) {
    var scheduled_time_ = localStorage.getItem('scheduled_time');
    var date: any;
    var time: any;
    var day: any;
    // if (scheduled_time_ && scheduled_time_ != null) {
    // console.log('scheduled')

    //   day = this.globals.s_day;
    //   time = this.globals.s_time;
    // } else {
    console.log('not scheduled');
    date = new Date();
    day = date.getDay();
    time = date.getHours() + '.' + date.getMinutes();
    // }

    if (day == 7) {
      day = 0;
    }

    var current_day = Timing[day];
    var n = current_day[0].indexOf('.');
    if (n != -1) {
      var res = current_day[0].split('.');
      current_day[0] = res[0] + '.' + '3';
    }
    var n1 = current_day[1].indexOf('.');
    if (n1 != -1) {
      var res = current_day[1].split('.');
      current_day[1] = res[0] + '.' + '3';
    }

    time = Number(time);
    if (current_day) {
      if (
        (Number(current_day[0]) <= time && Number(current_day[1]) > time) ||
        (Number(current_day[0]) <= time &&
          Number(current_day[1]) < Number(current_day[0]))
      ) {
        return true;
      } else if (current_day[0] == 'opened' && current_day[1] == 'opened') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    console.log('timings not set so item availble');
    return true;
  }
}
time_change() {
  var scheduled_time_ = localStorage.getItem("scheduled_time");
  if (scheduled_time_ && scheduled_time_ != "undefined") {
      let response = this.server.date_convert(scheduled_time_);
      response.subscribe(data => {
          console.log('.............',data)
          if (data.success == true) {
              this.globals.s_day = data.day_id + 1;
              this.globals.s_time = data.time;
          }

       
          
      }, error => {
          this.globals.presentToast("Something went wrong check your internet connection.")
      });
  }
  else{
    console.log('time not scheduled')
  }
}
}
