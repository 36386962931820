export const CONFIG = {
  business_username: 'mknx',
  business_id: '76',
  splash_text: 'SandBox',
  code_check: '0.003',
  //splash_image:'assets/animation/loader.svg',
  splash_image: '',

  //vegbar  432
  // guac 442
  //jalapenoshack 431
  //mknxbranches 153
  //sevenbrother 401
  //pauls 436
  //cusmos 135
  //flatbushexotics 409
  //guactimemain 460
  //tropicaljerkmain 380

  //    appId: "",
  //     googleProjectId: "",
  //      marketPlace: false,
  //       company: 'mknx',
  //        env: 'production'
};
