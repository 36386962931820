import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './services/resolver/data-resolver.service';

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
 
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  //    {
  //   path: '',
  //   redirectTo: 'tabs',
  //   pathMatch: 'full'
  // },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'deals',
    loadChildren: () => import('./deals/deals.module').then( m => m.DealsPageModule)
  },
  {
    path: 'deal-code',
    loadChildren: () => import('./deal-code/deal-code.module').then( m => m.DealCodePageModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('./slider/slider.module').then( m => m.SliderPageModule)
  },
 
  {
    path: 'item-preview',
    loadChildren: () => import('./item-preview/item-preview.module').then( m => m.ItemPreviewPageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./more/more.module').then( m => m.MorePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'sharelocation',
    loadChildren: () => import('./sharelocation/sharelocation.module').then( m => m.SharelocationPageModule)
  },
  {
    path: 'recent-fav',
    loadChildren: () => import('./recent-fav/recent-fav.module').then( m => m.RecentFavPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'business-list',
    loadChildren: () => import('./business-list/business-list.module').then( m => m.BusinessListPageModule)
  },
  
  {
    path: 'setup-profile',
    loadChildren: () => import('./setup-profile/setup-profile.module').then( m => m.SetupProfilePageModule)
  },
  
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'business-details',
   
    loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  },
  // {
  //   path: 'business-details/:id',
  //   resolve:{
  //     businessDetail:DataResolverService
  //   },
  //   loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  // },
  {

    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'add-review',
    loadChildren: () => import('./models/add-review/add-review.module').then( m => m.AddReviewPageModule)
  },
  {
    path: 'customize-item',
    loadChildren: () => import('./models/customize-item/customize-item.module').then( m => m.CustomizeItemPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'business-location',
    loadChildren: () => import('./business-location/business-location.module').then( m => m.BusinessLocationPageModule)
  },
  {
    path: 'user-location',
    loadChildren: () => import('./user-location/user-location.module').then( m => m.UserLocationPageModule)
  },
  {
    path: 'pickup-time',
    loadChildren: () => import('./models/pickup-time/pickup-time.module').then( m => m.PickupTimePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'update-password',
    loadChildren: () => import('./models/update-password/update-password.module').then( m => m.UpdatePasswordPageModule)
  },
  {
    path: 'login-modal',
    loadChildren: () => import('./login-modal/login-modal.module').then( m => m.LoginModalPageModule)
  },
  {
    path: 'confirm-order',
    loadChildren: () => import('./confirm-order/confirm-order.module').then( m => m.ConfirmOrderPageModule)
  },
  {
    path: 'payment-options',
    loadChildren: () => import('./payment-options/payment-options.module').then( m => m.PaymentOptionsPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./thankyou/thankyou.module').then( m => m.ThankyouPageModule)
  },
  {
    path: 'reward-notification',
    loadChildren: () => import('./reward-notification/reward-notification.module').then( m => m.RewardNotificationPageModule)
  },
  {
    path: 'order-placed-detail',
    loadChildren: () => import('./order-placed-detail/order-placed-detail.module').then( m => m.OrderPlacedDetailPageModule)
  },
  {
    path: 'user-points',
    loadChildren: () => import('./user-points/user-points.module').then( m => m.UserPointsPageModule)
  },
  {
    path: 'user-punches',
    loadChildren: () => import('./user-punches/user-punches.module').then( m => m.UserPunchesPageModule)
  },
  {
    path: 'user-qr',
    loadChildren: () => import('./user-qr/user-qr.module').then( m => m.UserQrPageModule)
  },
  {
    path: 'rewards',
    loadChildren: () => import('./rewards/rewards.module').then( m => m.RewardsPageModule)
  },
  {
    path: 'my-bookings',
    loadChildren: () => import('./my-bookings/my-bookings.module').then( m => m.MyBookingsPageModule)
  },
  {
    path: 'add-booking',
    loadChildren: () => import('./add-booking/add-booking.module').then( m => m.AddBookingPageModule)
  },
  {
    path: 'stylists',
    loadChildren: () => import('./stylists/stylists.module').then( m => m.StylistsPageModule)
  },
  {
    path: 'gift-cards',
    loadChildren: () => import('./gift-cards/gift-cards.module').then( m => m.GiftCardsPageModule)
  },
  {
    path: 'buy-giftcard',
    loadChildren: () => import('./buy-giftcard/buy-giftcard.module').then( m => m.BuyGiftcardPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'date-time',
    loadChildren: () => import('./models/date-time/date-time.module').then( m => m.DateTimePageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'my-rewards',
    loadChildren: () => import('./my-rewards/my-rewards.module').then( m => m.MyRewardsPageModule)
  },
  {
    path: 'photo-viewer',
    loadChildren: () => import('./photo-viewer/photo-viewer.module').then( m => m.PhotoViewerPageModule)
  },
  {
    path: 'table-numpad',
    loadChildren: () => import('./table-numpad/table-numpad.module').then( m => m.TableNumpadPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  //  {
  //   path: '',
  //   redirectTo: 'splash',
  //   pathMatch: 'full'
  // },
  {
    path: 'tip',
    loadChildren: () => import('./tip/tip.module').then( m => m.TipPageModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'select-branch',
    loadChildren: () => import('./select-branch/select-branch.module').then( m => m.SelectBranchPageModule)
  },
  {
    path: 'gift-type',
    loadChildren: () => import('./gift-type/gift-type.module').then( m => m.GiftTypePageModule)
  },
  {
    path: 'my-giftcards',
    loadChildren: () => import('./my-giftcards/my-giftcards.module').then( m => m.MyGiftcardsPageModule)
  },
  {
    path: 'branch-details',
    loadChildren: () => import('./branch-details/branch-details.module').then( m => m.BranchDetailsPageModule)
  },
  {
    path: 'signup-friend',
    loadChildren: () => import('./signup-friend/signup-friend.module').then( m => m.SignupFriendPageModule)
  },
  {
    path: 'points-redeem',
    loadChildren: () => import('./points-redeem/points-redeem.module').then( m => m.PointsRedeemPageModule)
  },


  {
    path: 'guest-login',
    loadChildren: () => import('./guest-login/guest-login.module').then( m => m.GuestLoginPageModule)
  },



  {
    path: '404',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  // { path: '**', redirectTo: '/404' },



];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
