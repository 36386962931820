import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { CONFIG } from './app-config.service';
import { LocalStorageService } from './core/local-storage.service';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root',
})
export class UserApiService {

  // httpOptions: { headers: HttpHeaders ,};
 

  loginPath: string;
  path:string;

  constructor(private http: HttpClient,public globals:GlobalVariable,
    private localStorageService: LocalStorageService,) {
    //    = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   }),
    // };
    if(this.globals.guestLogin!=true){
      let udid=this.localStorageService.getUdId('UdId')
      if(udid!=null && udid!='null'){
        this.globals.udid=udid;
      }
    }
   
  }
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public customerLogin(email, password): Observable<any> {
    // this.loginURL.URL

    console.log(this.globals.BaseUrl+"Customer_controller/login");
    let path  = this.globals.BaseUrl+"Customer_controller/login";
    let data = JSON.stringify({
      email: email,
      password: password,
      business_id:CONFIG.business_id
    });
    console.log(data);

    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }

  public customerSignUp(firstname, lastname,email,password,phone,birthday?,anniversary?): Observable<any> {
    // this.loginURL.URL

    // console.log(this.globals.BaseUrl+"Customer_controller/signup");
    let path  = this.globals.BaseUrl+"Customer_controller/signup";
    let data = JSON.stringify({
      firstname:firstname,
      lastname:lastname,
      email: email,
      password: password,
      phone:phone,
      birthday:birthday,
      business_id:CONFIG.business_id
    });
    console.log('signUp');
    console.log(data);

    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  public customerSignUpGiftcard(firstname, lastname,email,phone): Observable<any> {
    // this.loginURL.URL

    // console.log(this.globals.BaseUrl+"Customer_controller/signup");
    let path  = this.globals.BaseUrl+"giftcard/create_user";
    let data = JSON.stringify({
      first_name:firstname,
      last_name:lastname,
      email: email,
 
      phone:phone,
     
      business_id:CONFIG.business_id
    });
    // console.log('signUp');
    console.log(data);

    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  

  customerForgotPassword(email): Observable<any> {
    let path  = this.globals.BaseUrl+"Customer_controller/forgot_password";
    let data = JSON.stringify({
      email:email, 
      business_id:CONFIG.business_id
    });
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }

  customerProfileEdit(FirstName,Email,LastName,Password,Phone,Birthday,Anniversary): Observable<any> {
    let path  = this.globals.BaseUrl+"Customer_controller/update_profile";
    let data = JSON.stringify({
   
      // udid:this.localStorageService.getUdId('UdId'),
      firstname:FirstName,
      email:Email,
      lastname:LastName,
      password:Password,
      phone:Phone,
      birthday:Birthday,
      anniversary:Anniversary,
      business_id:CONFIG.business_id
      
    });
    console.log("customerProfileEdit:",data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }


  getBusiness(cords?,branch?): Observable<any> {
    console.log('cords..',cords,this.globals.mycoordinates)
    let type
    let path  = this.globals.BaseUrl+"Customer_controller/getPlaces";
    if(this.globals.branch_enabled==1  && !branch){
      type='branches'
    }
    else{
      type='main'
    }
    let data = JSON.stringify({
      business_id: CONFIG.business_id,
      business_type:type,
      coordinates:cords,
      udid:this.globals.udid,
      
     
    });
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getCategories(super_id?): Observable<any> {
    let path  = this.globals.BaseUrl+"menu/app_categories_new";
    // app_categories_new
    // categories_customer
    let data =JSON.stringify({business_id: CONFIG.business_id,udid:this.globals.udid});
    // let data =JSON.stringify({super_category_id:0, business_id: CONFIG.business_id ,platform:'app'});
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  
  getItemDetails(id): Observable<any> {
   let path = this.globals.BaseUrl+"menu/item_details";
    let data =JSON.stringify({ item_id: id});
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getAddress(cords): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_address_with_coordinates';
    var data = JSON.stringify({ business_id: CONFIG.business_id, coordinates: cords });
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  // getUserLotteryRewards
  getUserLotteryRewards(cords): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_rewards';
    var data = JSON.stringify({ udid: this.globals.udid, coordinates: cords });
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  // CheckUserPoints
  CheckUserPoints(): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/check_points';
    var data = JSON.stringify({ udid: this.globals.udid, b_id:CONFIG.business_id })
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  // LoadBanners
  LoadBanners(): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_banners';
    var data = JSON.stringify({ udid: this.globals.udid, business_id:CONFIG.business_id, app_version: this.globals.app_version })
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentThroughPayOnVenue(instruction, amount): Observable<any> {
    var path = (this.globals.BaseUrl + 'menu/place_order_r');
    var data = JSON.stringify({ udid: this.globals.udid, payment_info: '', order_info: this.globals.itemsInCart, instruction: instruction, total: amount, payment_type: 'Pay_on_venue', reservation_id: this.globals.reservation_id })
    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentOnDelivery(subtotal,Address, instruction, amount, order_date, Token, status): Observable<any> {
    let cartItems=this.globals.itemsInCart;
    cartItems.forEach(orderItems=>{
      if(orderItems.menuExtrasSelected.length>0){
      orderItems.menuExtrasSelected.forEach(option=>{
        console.log(option)
        if(option.hasOwnProperty('options')){
          console.log('has property', option.hasOwnProperty('options'));
          option.optionNameSelected=option.options
        }
      })
    }
    })

    if (this.globals.business_type == 'retail' || this.globals.business_type == 'salon') {
      var path = (this.globals.BaseUrl + 'retail/place_order_cash');
    }
    else {
      var path = (this.globals.BaseUrl + 'menu/place_order_cash');
  
    }
    console.log('cart items .... ',this.globals.itemsInCart)
    if(this.globals.guestLogin==true){
   let phone= this.localStorageService.getPhone('Phone');

    }
    var orderdata = JSON.stringify({ 
      udid: this.globals.udid, payment_info: {
         address: Address, token: Token
         }, 
         order_info: cartItems, instructions: instruction, total: amount,subtotal:subtotal, scheduled_time: order_date, payment_type: status, business_id: CONFIG.business_id })

    console.log(orderdata);
    return this.http
      .post<any>(path, orderdata,   )
      .pipe( catchError(this.handleError));
  }
  
  LotteryRedeeem(StorePoint, special_flag): Observable<any> {
    var path = this.globals.BaseUrl + 'business_controller/app_lottery_redeem_orders';
    var data = JSON.stringify({ username: CONFIG.business_id, qr_code: this.globals.udid, amount: StorePoint, is_special: special_flag });
    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  guestLogin(email,first_name,last_name,phone): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/guest_login';
    var data = JSON.stringify({ business_id: CONFIG.business_id,email:email,first_name:first_name,
      last_name:last_name, phone:phone});
    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  RedeemUserPoints(): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/redeem_points';
    var data = JSON.stringify({ udid: this.globals.udid, b_id: this.globals.bussinessId, amount: this.globals.points_availed })
    console.log("redeem points", data);
    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  userBusinessAvailedDiscount(): Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/customer_discount';
    var data = JSON.stringify({ udid: this.globals.udid, b_id: this.globals.bussinessId, discount_count: this.globals.availed_discount_count });

    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentThroughStripeReservation(instruction, amount, Token): Observable<any> {
    var path = (this.globals.BaseUrl + 'menu/place_order_r');
    var data = JSON.stringify({ udid: this.globals.udid, payment_info: { address: '', token: Token }, order_info: this.globals.itemsInCart, instruction: instruction, total: amount, payment_type: 'Stripe', reservation_id: this.globals.reservation_id })

    console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentThroughStripe(subTotal,Address, instruction, amount, order_date, Token, status, cash_discount, cardinfo?): Observable<any> {
    let cartItems=this.globals.itemsInCart
    cartItems.forEach(orderItems=>{
      if(orderItems.menuExtrasSelected.length>0){
      orderItems.menuExtrasSelected.forEach(option=>{
        console.log(option)
        if(option.hasOwnProperty('options')){
          console.log('has property', option.hasOwnProperty('options'));
          option.optionNameSelected=option.options
        }
      })
    }
    })
    if (this.globals.business_type == 'retail' || this.globals.business_type == 'salon') {
      var path = (this.globals.BaseUrl + 'retail/place_order');

    }
    else {
      var path = (this.globals.BaseUrl + 'menu/place_order');

    }
    if(this.globals.guestLogin==true){
      let phone= this.localStorageService.getPhone('Phone');
   
       }
console.log(orderdata, order_date)
    var orderdata = JSON.stringify({
     udid: this.globals.udid,
      payment_info: {
         cash_discount: cash_discount,
          address: Address, token: Token,
           cardInfo: cardinfo, 
           admin_stripe_enabled: this.globals.admin_stripe_enabled, 
           authorize_enabled: this.globals.authorize_enabled
           },
            order_info: cartItems,
             instruction: instruction,
              total: amount,
              subtotal:subTotal ,//added 1-20-2022
               scheduled_time: order_date,
                payment_type: status })
    console.log(orderdata);
    return this.http
      .post<any>(path, orderdata,   )
      .pipe(catchError(this.handleError));
  }
  GetUserOrderPlaced(): Observable<any> {
    if (this.globals.business_type == 'retail' || this.globals.business_type == 'salon') {
      var link = (this.globals.BaseUrl + 'retail/order_history');

    }
    else {
      var link = (this.globals.BaseUrl + 'menu/order_history');
    }
    var data = JSON.stringify({ udid: this.globals.udid,business_id: CONFIG.business_id });

    console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }

  getPoints(coordinates?) : Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_points';
    var data = JSON.stringify({ business_username:CONFIG.business_username,udid: this.globals.udid , coordinates :coordinates });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }

  getPunches(coordinates) : Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_punchcards';
    var data = JSON.stringify({ business_username:CONFIG.business_username,udid: this.globals.udid , coordinates :coordinates });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getUserLotteryRewardsNew(coordinates) : Observable<any> {
    var path = this.globals.BaseUrl + 'Customer_controller/get_rewards_new';
    var data = JSON.stringify({ udid: this.globals.udid , coordinates :coordinates });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getpunches_menuitems() : Observable<any> {
    var path = (this.globals.BaseUrl + 'rewards/get_punches_rewards');
    var data = JSON.stringify({ business_id: CONFIG.business_id,udid:this.globals.udid});
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  reward_notification() : Observable<any> {
    
    var path = this.globals.BaseUrl + 'Customer_controller/get_welcome_modal';
    var data = JSON.stringify({business_id: CONFIG.business_id,udid: this.globals.udid });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(path, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  
  get_all_Rewards_new() : Observable<any> {
    
    var link = this.globals.BaseUrl + 'rewards/get_customer_rewards';
    var data = JSON.stringify({ business_id:CONFIG.business_id,udid: this.globals.udid });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getrewards_menuitems() : Observable<any> {
    
    var link = (this.globals.BaseUrl + 'rewards/get_reward_list');
    var data = JSON.stringify({ business_id: CONFIG.business_id ,udid:this.globals.udid});
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  
  getpoints_menuitems() : Observable<any> {
    
    var link = (this.globals.BaseUrl + 'rewards/get_point_rewards');
    var data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  
  GetPunchcards(coordinates?) : Observable<any> {
    
    var link = this.globals.BaseUrl + 'Customer_controller/get_punchcards';
    var data = JSON.stringify({ udid: this.globals.udid , coordinates :coordinates,business_username:CONFIG.business_username});
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  redeem_point_menu_reward(reward_id,reward_type ){
    var link = this.globals.BaseUrl + 'rewards/get_redeem_points';
    
    var data = JSON.stringify({ business_id:CONFIG.business_id,udid:this.globals.udid,item_id:reward_id,reward_type:reward_type });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
   special_offer_banners() {
    var link = this.globals.BaseUrl + 'business_controller/get_special_offer_banners';
    var data = JSON.stringify({ business_id: CONFIG.business_id});
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }
  getReviews() {
    var link = this.globals.BaseUrl + 'Customer_controller/getbusinessinfo';
    var data = JSON.stringify({ business_username: CONFIG.business_username });
    console.log( data);
    // console.log(data);
    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
  }

  AddUserReview(user_email, description, rating) {
    var link = this.globals.BaseUrl + 'Customer_controller/review';
   
   var data = JSON.stringify({ useremail: user_email, title: '', description: description, rating: rating, business: CONFIG.business_username });
   console.log( data);
   // console.log(data);
   return this.http
     .post<any>(link, data,   )
     .pipe(retry(2), catchError(this.handleError));
 }
   date_convert(date) {
    var link = this.globals.BaseUrl + '/menu/get_date_convert';
    var data = JSON.stringify({ date:date });

    console.log( data);

    return this.http
      .post<any>(link, data,   )
      .pipe(retry(2), catchError(this.handleError));
}
booking_history() {
  var link = this.globals.BaseUrl + 'salon/booking_history';
  console.log(this.globals.bussinessId)
  var data = JSON.stringify({ udid:this.globals.udid,business_id: CONFIG.business_id});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
get_services_categories() {

  var link = this.globals.BaseUrl + 'salon/serviceCategories';
  
  var data = JSON.stringify({ business_id: CONFIG.business_id });
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
get_services(category_id) {

  var link = this.globals.BaseUrl + 'salon/get_services';
 
  var data = JSON.stringify({ business_id:CONFIG.business_id ,category_id:category_id });
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
get_stylist(service_id) {

  var link = this.globals.BaseUrl + 'salon/stylistlist';

  var data = JSON.stringify({ business_id: CONFIG.business_id,service_id : service_id});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}

get_slots(service_id,stylist_id,scedule_time) {
  var link = this.globals.BaseUrl + 'salon/get_stylist_timing';
  console.log("Time: ",scedule_time)
  var data = JSON.stringify({ business_id:  CONFIG.business_id,service_id : service_id,stylist_id:stylist_id,schedule_time:scedule_time});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}

getPickupslots(id, time,type): Observable<any> {
  var link = this.globals.BaseUrl + 'events/businessSlots';
  var data;
  data = JSON.stringify({
    // user_timezone_set: this.timeZone,
    business_id: id,
    schedule_time: time,
    type:type
  });
  console.log(data);
  return this.http
    .post<any>(link, data)
    .pipe(retry(1), catchError(this.handleError));
}

booking_salon(service_id,stylist_id,time_slot,p_detail,amount_data,notes) {

  var link = this.globals.BaseUrl + 'salon/booking';

  var data = JSON.stringify({ udid:this.globals.udid,business_id: CONFIG.business_id,service_id : service_id,stylist_id:stylist_id,time_slot:time_slot,p_details:p_detail,raw_data:amount_data,notes:notes});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
my_gift_cards() {
  var link = (this.globals.BaseUrl + 'giftcard/get_customer_giftcards');
  var data = JSON.stringify({ business_id: CONFIG.business_id, udid: this.globals.udid});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
booking_cancel(bookingId) {
  var link = this.globals.BaseUrl + 'salon/cancel_booking';
 
  var data = JSON.stringify({ id: bookingId, business_id: CONFIG.business_id});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
my_gift_cards_design() {
  var link = (this.globals.BaseUrl + 'giftcard/get_giftcard_designs');
  var data = JSON.stringify({ business_id:CONFIG.business_id});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}


gift_cards() {
  var link = (this.globals.BaseUrl + 'giftcard/get_business_giftcards');
  var data = JSON.stringify({ business_id: CONFIG.business_id});
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
public buyGiftCardByCreditCard(token, id, design_id, amount, message,receieverUDID,cardinfo?): Observable<any> {
  var data = JSON.stringify({ udid_r: receieverUDID, business_id: CONFIG.business_id,card_info:cardinfo, udid_s: this.globals.udid, token: token, giftcard_id: id, design_id: design_id, amount: amount, message: message });
  var link = this.globals.BaseUrl+'giftcard/buy_giftcard';
  console.log(data)
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
public buyGiftCardByCash(id, design_id, amount, message): Observable<any> {
  var data = JSON.stringify({ udid_r:  this.globals.udid, business_id: CONFIG.business_id, udid_s:  this.globals.udid, giftcard_id: '', design_id: design_id, amount: amount, message: message });
  var link = this.globals.BaseUrl+'giftcard/buy_giftcard_cash';
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
gallery_list() {
  var link = (this.globals.BaseUrl + 'events/get_gallery_list');
  var data = JSON.stringify({ business_id: CONFIG.business_id});
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
GetWalletCategories() {
  var link = this.globals.BaseUrl + "Customer_controller/get_wallet_categories";
  return this.http
  .get<any>(link,   )
  .pipe(retry(2), catchError(this.handleError));
}
privacyPolicy(type) {
  var link = (this.globals.BaseUrl + 'Landingpage_controller/business_policies');
  var data = JSON.stringify({ business_id: CONFIG.business_id,type:type});
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
search_user(email) {
  var link = (this.globals.BaseUrl + 'giftcard/search_user');
  var data = JSON.stringify({ business_id:CONFIG.business_id , email: email});
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
welcomeBanners() {
  var link = (this.globals.BaseUrl + 'Customer_controller/get_welcome_screen');
  var data = JSON.stringify({ business_id:CONFIG.business_id});
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}

verifyCoupon(coupon) {

  var link = (this.globals.BaseUrl + 'customer_controller/verifyCoupon');
  var data = JSON.stringify({ business_id:CONFIG.business_id,udid:this.globals.udid,coupon:coupon });
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}

welcomeSlides() {

  var link = (this.globals.BaseUrl + 'customer_controller/get_welcome_banners');
  var data = JSON.stringify({ business_id:CONFIG.business_id });
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}

rewardsSlides() {
  var link = (this.globals.BaseUrl + 'rewards/get_reward_banners');
  var data = JSON.stringify({ business_id:CONFIG.business_id });
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}
deleteProfile() {
  var link = (this.globals.BaseUrl + 'customer_controller/deleteCustomerProfile');
  var data = JSON.stringify({ business_id:CONFIG.business_id,udid:this.globals.udid });
  console.log(data);
  return this.http
  .post<any>(link, data,   )
  .pipe(retry(2), catchError(this.handleError));
}



getHostInfo() {
  var link = (this.globals.BaseUrl + 'customer_controller/get_website_business_info');
// const data =new FormData();
let url=window.location.hostname; 
// console.log(url)
// data.append('host',url);
// var options = { 'host': url };
// var data = JSON.stringify({ host: window.location.hostname});
var formData=new FormData();
console.log(formData);
formData.append('host', url);

// var options = { content: formData };

  return this.http.post<any>(link, formData)
  .pipe(retry(2), catchError(this.handleError));
}
}
// CONFIG.business_id