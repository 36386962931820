import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-customize-item',
  templateUrl: './customize-item.page.html',
  styleUrls: ['./customize-item.page.scss'],
})
export class CustomizeItemPage implements OnInit {
  @Input()value
  itemDetails
  extras=[]
  constructor(public modalController: ModalController) {
    
   }

  ngOnInit() {
    console.log('data found => ',this.value)
    this.itemDetails=this.value.item
    this.extras=this.value.extras
    console.log('extras',this.extras)
  }
  close() {

    this.modalController.dismiss({
      'dismissed': true,
      data:''
    });
}
}
