import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { tick } from '@angular/core/testing';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';

import { GlobalVariable } from '../global';
import { LocalStorageService } from '../services/core/local-storage.service';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.page.html',
  styleUrls: ['./guest-login.page.scss'],
})
export class GuestLoginPage implements OnInit {
  @ViewChild('text_input')input;
  guestForm: FormGroup;
  isSubmitted=false;
  formatedPhone: string;

  constructor(public modalCtrl:ModalController,
    public fb:FormBuilder,
    public server:UserApiService,
    public globals:GlobalVariable,
    public localStorageService:LocalStorageService,
    public navCtrl:NavController,
    
    ) { }

  ngOnInit() {
    this.guestForm = this.fb.group({
      email: new FormControl(
        '',
        Validators.compose([
          // Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      firstname: new FormControl(
        '',
        Validators.compose([])
      ),
      lastname: new FormControl(
        '',
        Validators.compose([

        ])
      ),
      phone: new FormControl(
       '',
        Validators.compose([Validators.required, Validators.minLength(14)])
      ),
    });
  }
  close(){
this.modalCtrl.dismiss();
  }
  get errorControl() {
    return this.guestForm.controls;
  }
  guestLogin(){
    this.isSubmitted = true;
    var cleaned = ('' + this.guestForm.value.phone).replace(/\D/g, '');
    if (!this.guestForm.valid) {
      console.log('Please provide all the required values!');
      return false;
    } else {
      this.globals.loader()
      console.log(this.guestForm.value);
      this.server.guestLogin(this.guestForm.value.email,
        this.guestForm.value.firstname,
        this.guestForm.value.lastname,
        '+1'+cleaned).subscribe(res=>{

          this.globals.dismiss();
          console.log(res);
          if(res.success){
            this.globals.guestLogin=true;
    this.localStorageService.setFirstName('FirstName',res.first_name);
    // this.localStorageService.setPhone('Phone',res.phone);
    this.globals.udid=res.udid;
    this.globals.Email=res.email;

    this.modalCtrl.dismiss();
    if(this.globals.takeMeToCart==true){
      this.navCtrl.navigateRoot('/cart')
    this.globals.takeMeToCart=false;

    }
    else{
      if(this.globals.inWeb){
        this.navCtrl.navigateRoot('/tabs/tabs/menu')

      }
      else{
    this.navCtrl.navigateRoot('/tabs/tabs/home')

      }

    }
    // this.navCtrl.navigateRoot('/tabs/tabs/home')


//             anniversary: ""
// birthday: ""
// customer_type: "guest"
// date_joined: ""
// email: ""
// first_name: "name"
// firstname: "name"
// id: "108597"
// image: ""
// last_name: ""
// lastname: ""
// mobile_verification_amount: ""
// phone: "+167898765456789"
// phone_verified: "false"
// profile_complete: true
// qr_text: "3ba4e12d8a"
// success: true
// udid: "3ba4e12d8a"
// url: "htt

          }
        },err=>{
          this.globals.dismiss();

          this.globals.presentToast('Network error');
        })
    }
  }
  numberChange() {
    console.log('here');
    // this.formatedPhone=''
    let elem =<HTMLIonInputElement>document.getElementById('text_input');
    // elem.focus()
    console.log(elem)
    let length=String(elem.value).length
    console.log(length)
    elem.setFocus()
    // tick()
      // elem.setSelectionRange(length,length)
    // elem.
  
    // this.phonenumber = String(phonenumber);
    console.log(this.guestForm.value.phone,'form')
    var cleaned = (this.guestForm.value.phone).replace(/\D/g, '');
    console.log(cleaned)
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      // console.log(match)
      this.formatedPhone =
        '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3];
      //  this.phonenumber= this.formatedPhone
      // console.log(this.formatedPhone);
      // let value=elem.getAttribute('value');
      // let length=elem.value.length;
      // this.input.nativeElement.setSelectionRange(length,length);
      // this.input.nativeElement.focus()
      // elem.setSelectionRange(length,length)
      // this.input.nativeElement.setFocus()
      // elem.focus()

      // console.log(elem,'dddd')
      
    }
    // var match = cleaned.match( /^(\d{3})(\d{3})(\d{4})$/)
    // if (match) {

    //     console.log('match')
    //   this.formatedPhone= '(' + match[1] + ') ' + match[2] + '-' + match[3]
    // }
    return null;
    // var clean = this.formatedPhone.replace(/[^\d]/g, '')
    // console.log(clean)
    // this.formatedPhone = clean.replace(/[0-9]{3}-[0-9]{3}-[0-9]{4}/g,'');
    // console.log(this.formatedPhone)
  }
}
